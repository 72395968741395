<template>
  <div class="add_credentials">
    <el-card>
      <div slot="header" class="clearfix">
        <span v-if="certId === 0" class="leftTitle">创建证书</span>
        <span v-else class="leftTitle">编辑证书</span>
      </div>
      <el-form ref="form" :model="form" label-width="120px" :disabled="isEdit == 1" :rules="rules">
        <div style="display: flex;">
          <div class="leftDiv"></div>
          <div class="leftTitle" style="margin-bottom: 20px;">基本信息</div>
        </div>

        <el-form-item label="证书名称:" prop="certName">
          <el-input
            v-model="form.certName"
            placeholder="请输入2-28字的证书名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="证书分类:" prop="productAttributeCategoryId">
          <el-select
            v-model="form.productAttributeCategoryId"
            placeholder="请选择证书分类"
            clearable
            @change="changeSelect"
          >
            <el-option
              v-for="item in selectTypeList"
              :key="item.productAttributeCategoryId"
              :value="item.productAttributeCategoryId"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="productId === 3" label="证书等级:" prop="attrList">
          <!--  <el-checkbox-group v-model="form.attrList">
            <el-checkbox v-for="(item, index) in level" :key="index" :label="item"></el-checkbox>
          </el-checkbox-group> -->

          <el-checkbox-group v-model="checkboxList">
            <el-checkbox v-for="(item, index) in level" :key="index" :label="item"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="证书归属地:" prop="addressCode">
          <el-cascader
            ref="myCascader"
            v-model="form.addressCode"
            :options="total"
            :props="{
              value: 'value',
            }"
            placeholder="请选择证书归属地"
            @change="handleAddressChange"
          >
          </el-cascader>
        </el-form-item>

        <el-form-item ref="pic" label="封面图片" prop="pic">
          <upload-image
            v-model="form.pic"
            :width-size="750"
            :height-size="420"
            :upload-img="$refs.pic"
          ></upload-image>
          <p class="tip">图片尺寸(750 * 420)，大小5MB内，支持扩展名：.png .jpg .jpeg</p>
        </el-form-item>

        <div style="display: flex;">
          <div class="leftDiv"></div>
          <div class="leftTitle" style="margin-bottom: 20px;">报名资料</div>
        </div>

        <el-form-item label="报名申请表:" prop="applyList">
          <upload-file
            v-model="form.applyList"
            :file-type="'.docx,.doc'"
            :is-one-file="true"
            :limit="1"
          ></upload-file>
          <p class="uploadFileText">单个文件大小5MB，支持扩展名.docx .doc, 仅支持上传一个文件</p>
        </el-form-item>

        <el-form-item ref="tinymceRules" label="报名材料说明:" prop="materialDescription">
          <div>用户证书报名时所需要的材料清单说明</div>
          <tinymce
            ref="editor"
            v-model="form.materialDescription"
            :disabled="isEdit == 1 ? true : false"
            :width="750"
            :height="300"
          ></tinymce>
        </el-form-item>

        <el-form-item ref="enclosureList" label="附件:" prop="enclosure">
          <MultipleUploadImg ref="uploadImg" v-model="form.enclosure"></MultipleUploadImg>
          <p class="tip">单个文件大小5MB，支持扩展名：.png .jpg.jpeg（最多可上传5个文件）</p>
        </el-form-item>

        <el-form-item v-if="isEdit == 0">
          <el-button type="primary" :loading="loading" @click="submit('form')">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import UploadImage from '@/components/UploadImage'
import Tinymce from '@/components/Tinymce'
import UploadFile from '@/components/UploadFile'
import total from '../../../assets/address/city'
import {
  createdCertificate,
  attrSelect,
  certificateDetail,
  certificateUpdate,
} from '@/api/certCenter'
import MultipleUploadImg from '@/components/MultipleUploadImg'
import to from 'await-to'
export default {
  components: {
    UploadImage,
    MultipleUploadImg,
    UploadFile,
    Tinymce,
  },
  data() {
    var checked = (rule, value, callback) => {
      if (value.length == 0) {
        return callback(new Error('请选择证书等级'))
      } else {
        callback()
      }
    }
    return {
      checkboxList: [],
      certId: 0,
      total,
      form: {
        // attrList: [],
        enclosure: [],
        certLevel: [],
      },
      selectTypeList: [],
      level: ['一级', '二级', '三级', '四级', '五级'],
      productId: '',
      loading: false,
      rules: {
        certName: [
          { required: true, message: '请输入证书名称', trigger: 'blur' },
          { min: 2, max: 28, message: '请输入2-28字证书名称', trigger: 'blur' },
        ],
        productAttributeCategoryId: [
          { required: true, message: '请选择证书分类', trigger: 'change' },
        ],
        addressCode: [{ required: true, message: '请选择证书归属地', trigger: 'change' }],
        // attrList: [{ required: true, validator: checked, trigger: 'change' }],
        checkboxList: [{ required: true, validator: checked, trigger: 'change' }],
        pic: [{ required: true, message: '请上传商品封面图', trigger: 'blur' }],
        applyList: [{ required: true, message: '请上传报名资料', trigger: 'change' }],
        materialDescription: [{ message: '请填写报名材料说明', trigger: 'blur' }],
        // issuingAgency: [
        //   { required: true, message: '请输入发证机构', trigger: 'blur' },
        //   { min: 2, max: 24, message: '请输入2-24字证书名称', trigger: 'blur' },
        // ],
      },
      isEdit: 0,
    }
  },
  watch: {
    'form.materialDescription': function(newValue) {
      if (newValue || newValue !== '' || newValue !== undefined || newValue !== null) {
        this.$refs.tinymceRules.clearValidate()
      }
    },
  },
  mounted() {
    const { certId, isEdit } = this.$route.params

    if (certId) {
      console.log(isEdit)
      this.isEdit = isEdit
      console.log(certId)
      this.certId = certId
      this.certificateDetail(this.certId)
    }
  },
  created() {
    this.attrSelectData()
  },
  methods: {
    contains(arrays, obj) {
      var i = arrays.length
      while (i--) {
        if (arrays[i] === obj) {
          return i
        }
      }
      return false
    },
    async certificateDetail(certId) {
      const [res, err] = await to(certificateDetail({ certId: certId }))
      if (err) return this.$message({ type: 'error', message: err.msg })

      this.form = res.data
      //归属地
      this.form.addressCode = [res.data.provinceCode, res.data.cityCode]
      //附件
      if (this.form.enclosure) {
        let enclosure = this.form.enclosure.split(',')
        this.form.enclosure = enclosure.map(item => ({ status: 'success', url: item }))
      }

      //报名资料
      this.form.applyList = JSON.parse(this.form.applyFormList)

      //等级
      if (res.data.productAttributeCategoryId == 3) {
        let arrTitle = []
        res.data.certLevel.split(',').map(item => {
          arrTitle.push(this.level[item - 1])
        })

        if (arrTitle.length > 0) {
          this.productId = res.data.productAttributeCategoryId
          this.checkboxList = arrTitle
        }
      } else {
        this.productId = res.data.productAttributeCategoryId
        this.checkboxList = []
      }
    },
    async submit(formName) {
      if (this.certId === 0) {
        console.log('创建')
        this.$refs[formName].validate(async valid => {
          if (valid) {
            this.loading = true
            this.form.applyForm = this.form.applyList[0].url

            let lists = JSON.stringify(this.form.applyList)

            console.log(lists)
            this.form.applyFormList = lists
            if (this.form.enclosure) {
              this.form.enclosure = this.form.enclosure.map(item => item.url).join(',')
            }

            let arrayIndex = []
            this.checkboxList.map(item => {
              let index = this.contains(this.level, item)
              arrayIndex.push(index + 1)
            })
            this.form.certLevel = arrayIndex.join(',')

            const [, err] = await to(createdCertificate(this.form))
            this.loading = false
            if (err) return this.$message.error(err.msg)
            this.$message.success('添加成功')
            this.$router.push('/certCenter/certManger')
          }
        })
      } else {
        console.log('修改')
        console.log(this.form)
        this.$refs[formName].validate(async valid => {
          if (valid) {
            this.loading = true

            this.form.applyForm = this.form.applyList[0].url
            let lists = JSON.stringify(this.form.applyList)

            console.log(lists)
            this.form.applyFormList = lists

            let arrayIndex = []
            this.checkboxList.map(item => {
              let index = this.contains(this.level, item)
              arrayIndex.push(index + 1)
            })
            this.form.certLevel = arrayIndex.join(',')
            if (this.form.enclosure) {
              this.form.enclosure = this.form.enclosure.map(item => item.url).join(',')
            }
            const [, err] = await to(certificateUpdate(this.form))
            this.loading = false
            if (err) return this.$message.error(err.msg)
            this.$message.success('修改成功')
            this.$router.push('/certCenter/certManger')
          }
        })
      }
    },
    handleAddressChange(value) {
      this.form.provinceCode = value[0]
      this.form.cityCode = value[1]
      for (var i = 0; i < this.total.length; i++) {
        var item = total[i]
        if (item.value == value[0]) {
          this.form.provinceName = item.label
        }
        if (item.children.length > 0) {
          for (var k = 0; k < item.children.length; k++) {
            var twoItem = item.children[k]
            if (twoItem.value == value[1]) {
              this.form.cityName = twoItem.label
            }
          }
        }
      }
    },
    async attrSelectData() {
      const [res, err] = await to(attrSelect({}))
      if (err) return this.$message.error(err.msg)
      this.selectTypeList = res.data
    },
    changeSelect(val) {
      this.productId = val
      if (val != 3) {
        this.checkboxList = []
      }

      //   const [res, err] = await to(attrSelect({}))
      //   if (err) return this.$message.error(err.msg)
      // console.log(res.data)
      //   res.data.map(v => {
      //     if (v.productAttributeCategoryId == val) {
      //       if (v.attrDict.specificationList.length !== 0) {
      //         this.level = v.attrDict.specificationList[0].inputList
      //       } else {
      //         this.level = []
      //       }
      //     }
      //   })
    },
  },
}
</script>

<style scoped lang="scss">
.add_credentials {
  .el-form-item {
    margin-bottom: 30px !important;
    &:last-child {
      margin-top: 50px;
    }
  }
  .tip {
    font-size: 12px;
    color: #909399;
  }
  .uploadFileText {
    color: #909399;
    font-size: 12px;
  }
}

.leftDiv {
  width: 4px;
  height: 18px;
  background: #ff7b33;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-left: 10px;
  margin-right: 10px;
}

.leftTitle {
  width: 72px;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #4d4d4d;
  line-height: 20px;
}
</style>
